export function sMsg(msg) {
  layer.msg(msg, {
    icon: 1,
    tipsMore: true
  })
  return msg
}

export function eMsg(msg) {
  layer.msg(msg, {
    icon: 2,
    tipsMore: true
  })
  return msg
}

export function eMsgVerify(msg) {
  layer.msg(msg, {
    icon: 5,
    tipsMore: true
  })
  return msg
}

let loadingLayerIndex = null
export const simpleLoading = {
  show() {
    loadingLayerIndex = layer.msg('加载中', {
      icon: 16,
      shade: 0.8,
      time: 0
    })
  },
  hide() {
    layer.close(loadingLayerIndex)
  }
}
