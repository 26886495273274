import { setUpgradeToken, setFakeToken } from '@/utils/url'
import { getToken, setToken, removeToken } from '@/utils/login'
import { get, postData } from '@/utils/request'

export function getLoginPublicKey() {
  return get<{ publicKey: string }>('v2/auth/getKey')
}

export function getValidateKey() {
  return get<{ loginTokenKey: string }>('v2/auth/get/validate/key')
}


export function getAllCourtList() {
  return get<{ id: number; courtName: string }[]>('common/getDeployCourtList')
}

export async function getPhone(userName: string) {
  return get(`v2/auth/forget/info/${userName}`)
}

export async function sendCode(userName: string) {
  return get(`v2/auth/sendCode/${userName}`)
}

const loginUrl = 'v2/auth/getAccessToken'

export async function simpleLogin(data: SimpleLoginData) {
  const res = await postData(loginUrl, data)
  if (res && res.accessToken) {
    setToken(res.accessToken)
  } else {
    removeToken()
    throw new Error('获取token失败')
  }
}

export interface SimpleLoginData {
  courtId?: string | number
  userName: string
  password: string
  validateKey: string
  validateCode: string
}

export async function queryAllOrgOrCourt() {
  const courtRes = await get('user/courts')
  let courts: { id: number; name: string }[] = []
  if (Array.isArray(courtRes) && courtRes.length > 0) {
    courts = courtRes.map((item) => ({ id: item.id, name: item.courtName }))
  }

  const orgRes = await get('user/organizations')
  let orgs: { id: number; name: string }[] = []
  if (Array.isArray(orgRes) && orgRes.length > 0) {
    orgs = orgRes.map((item) => ({ id: item.id, name: item.organizationName }))
  }

  return { courts, orgs }
}

export async function loginWithOrgRole(organizationId: string | number) {
  const exchangeToken = getToken()
  const res = await postData(loginUrl, { exchangeToken, organizationId })
  if (res && res.accessToken) {
    setToken(res.accessToken)
  } else {
    throw new Error('获取token失败')
  }
}

export async function loginWithCourtRole(courtId: string | number) {
  const exchangeToken = getToken()
  const res = await postData(loginUrl, { exchangeToken, courtId })
  if (res && res.accessToken) {
    setToken(res.accessToken)
  } else {
    throw new Error('获取token失败')
  }
}

export async function loginByTdh(tokenId: string) {
  const res = await get(`v2/auth/getAccessTokenByTdhUserId/${tokenId}`)
  if (res && res.accessToken) {
    setToken(res.accessToken)
    localStorage.removeItem('loginMode')
  } else {
    throw new Error('获取token失败')
  }
}

export async function loginByTdhForCase(
  courtId: string | number,
  thdUserId: string | number
) {
  const res = await get(
    `v2/auth/getAccessTokenByTdhUserId?courtId=${courtId}&thdUserId=${thdUserId}`
  )
  if (res && res.accessToken) {
    setToken(res.accessToken)
    localStorage.removeItem('loginMode')
  } else {
    throw new Error('获取token失败')
  }
}

export async function loginForUpgrade() {
  const res = await get('v2/auth/deploy/token')
  if (res) {
    setUpgradeToken(res)
  } else {
    throw new Error('获取token失败')
  }
}

export async function loginForBankruptcy(concatId: string) {
  const res = await get(`v2/auth/getTokenId/${concatId}`)
  if (res) {
    return res;
  } else {
    throw new Error('获取token失败')
  }
}

export async function loginByBankruptcy(tokenid: string) {
  const res = await get(`v2/auth/getAccessTokenByTokenId/${tokenid}`)
  if (res) {
    setFakeToken(res.token.accessToken)
    return res
  } else {
    throw new Error('获取token失败')
  }
}

export async function getUserInfo() {
  const res = await get('orgUser/userInfo')
  localStorage.setItem('courtId', res?.courtId)
  if (res.courtId) {
    const packageRes = await get('court/config/detail')
    localStorage.setItem('packageTarget', packageRes?.packageTarget)
  }
  const orgOrCourtName: string =
    res.courtName || res.organizationName || '未知机构或法院'
  return {
    courtId: res.courtId as number | null,
    courtName: res.courtName as string | null,
    orgOrCourtName,
    userId: res.userId as number,
    userName: res.userName as string,
  }
}

export async function getCommonEnv() {
  const res = await get('common/env')
  localStorage.setItem('env', res)
}

export async function getUserPermission() {
  try {
    const res = await get('orgUser/userPermissions')
    const list = res && res.menuList
    return Array.isArray(list) ? list : []
  } catch (_) {}
  return []
}

// 修改密码
export function updateUserPassword(password: string) {
  return postData('user/self/updatePassword', { password })
}

// 修改密码
export function forgetPassword(obj: any) {
  return postData('v2/auth/forget/password', obj)
}

// 校验密码
export function checkPassword(obj: any) {
  return postData('v2/auth/forget/check', obj)
}

